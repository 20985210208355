import React from "react";
import { ImageBackground, TouchableOpacity, View } from "react-native";
import style from "./style";
import api from "../config/api_urls";
import AsyncStorage from "@react-native-community/async-storage";

//ordre 1
export default class Login extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = props.route.params;
    this.state["gameInfo"] = [];
  }

  componentDidMount() {
    this.getNom();
    AsyncStorage.setItem("anarCredits", true);
  }

  getNom = async () => {
    try {
      const value = await AsyncStorage.getItem("nom");
      if (value !== null) {
        this.setState({ nom: value });
      }
    } catch (e) {
      console.log(e);
    }
  };

  changeScreen() {
    setTimeout(() => {
      if (this.state.nom) {
        console.log("entra");
        //   this.props.navigation.navigate('Prova', this.state);
        this.props.navigation.navigate("Home", this.state);
      } else {
        this.props.navigation.navigate("Login", this.state);
      }
    }, 100);
  }

  render() {
    const url = this.state.projecte.recursos.find(
      (e) => e.tipus_recurs === "01_background_qr"
    )?.["imatge_recurs" + this.state.idioma]?.url;

    if (
      url &&
      this.state.device !== "phone" &&
      this.state.device !== "tablet" &&
      this.state.device !== "unknown"
    ) {
      return (
        <ImageBackground
          source={{
            uri: api.api_url + url,
          }}
          style={{
            flex: 1,
            width: "100vw",
            height: "100%",
            resizeMode: "contain",
          }}
        ></ImageBackground>
      );
    }

    return (
      <View style={style.container}>
        <TouchableOpacity
          style={style.TouchableOpacity}
          onPress={() => {
            this.changeScreen();
          }}
        >
          <ImageBackground
            source={
              this.state.projecte["FonsPantalla1" + this.state.idioma]
                ? {
                    uri:
                      api.api_url +
                      this.state.projecte["FonsPantalla1" + this.state.idioma]
                        .url,
                  }
                : null
            }
            style={style.imageBackground}
          ></ImageBackground>
        </TouchableOpacity>
      </View>
    );
  }
}
