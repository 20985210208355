import React from "react";
import {
  Dimensions,
  Image,
  ImageBackground,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import style from "./style";
import api from "../config/api_urls";
import * as Analytics from "expo-firebase-analytics";
import {
  setAnalyticsCollectionEnabled,
  setCurrentScreen,
} from "expo-firebase-analytics";

//ordre 3
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.route.params;
    this.state["nom"] = "";
    this.state["boto"] = "";
  }

  componentDidMount() {
    this.carregarTraducio();
    console.log(this.state.projecte.botoLogin[0].url);
  }

  carregarTraducio() {
    //  console.log(this.state.idioma);
    switch (this.state.idioma) {
      case "_ES":
        this.setState({
          boto: this.state.projecte["IniciarSessioLogin" + this.state.idioma],
        });
        this.setState({
          placeholder: this.state.projecte["NomLogin" + this.state.idioma],
        });
        break;
      case "_FR":
        this.setState({
          boto: this.state.projecte["IniciarSessioLogin" + this.state.idioma],
        });
        this.setState({
          placeholder: this.state.projecte["NomLogin" + this.state.idioma],
        });
        break;
      case "_EN":
        this.setState({
          boto: this.state.projecte["IniciarSessioLogin" + this.state.idioma],
        });
        this.setState({
          placeholder: this.state.projecte["NomLogin" + this.state.idioma],
        });
        break;
      default:
        this.setState({
          boto: this.state.projecte["IniciarSessioLogin" + this.state.idioma],
        });
        this.setState({
          placeholder: this.state.projecte["NomLogin" + this.state.idioma],
        });
        break;
    }
  }

  async send() {
    if (this.state.nom != null && this.state.nom != "") {
      await setCurrentScreen("HaFetLogin");
      Analytics.logEvent("HaFetLogin", {
        screen: "Login",
        propouse: "Ha fet login",
      });
      await setAnalyticsCollectionEnabled(true);
      AsyncStorage.setItem("nom", this.state.nom);
      this.props.navigation.navigate("Home", this.state);
    }
  }
  //Sistema de cordenades per moure l'altura i la llargada Alutra 1-100 Llargada npi
  mostrarBarraDeLogin() {
    if (
      this.state.projecte.PosicioAltura != null &&
      this.state.projecte.PosicioAltura >= 0
    ) {
      return (
        <View
          style={{
            position: "absolute",
            top:
              (Dimensions.get("window").height / 100) *
              this.state.projecte.PosicioAltura,
            right:
              this.state.projecte.PosicioLlargada >= 0 &&
              this.state.projecte.PosicioLlargada != null
                ? Dimensions.get("window").width / 2 -
                  (Dimensions.get("screen").width / 100) *
                    this.state.projecte.PosicioLlargada
                : "20%",
          }}
        >
          <TextInput
            autoCorrect={false}
            placeholder={this.state.placeholder}
            placeholderTextColor={style.colorCorporatiu}
            //underlineColorAndroid="transparent"
            returnKeyType="send"
            onChangeText={(text) => this.setState({ nom: text })}
            style={style.textNom}
            value={this.state.nom}
          />
          <TouchableOpacity
            onPress={() => {
              this.send();
            }}
          >
            <Text
              style={[
                style.textLogin,
                { color: this.state.projecte.ColorCorporatiuText2 },
              ]}
            >
              {this.state.boto}
            </Text>
          </TouchableOpacity>
        </View>
      );
    } else {
      return (
        <View style={{ alignContent: "center", paddingTop: 10 }}>
          <TextInput
            autoCorrect={false}
            placeholder={this.state.placeholder}
            placeholderTextColor={style.colorCorporatiu}
            //underlineColorAndroid="transparent"
            returnKeyType="send"
            onChangeText={(text) => this.setState({ nom: text })}
            style={style.textNom}
            value={this.state.nom}
          />
          <TouchableOpacity
            onPress={() => {
              this.send();
            }}
          >
            <Text
              style={[
                style.textLogin,
                { color: this.state.projecte.ColorCorporatiuText2 },
              ]}
            >
              {this.state.boto}
            </Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  //<Image style={{ height: this.state.pop_up_height * 0.7, resizeMode: 'contain' }} source={{ uri: api.api_url + this.state.projecte.ImatgeLogin.url }} />
  render() {
    return (
      <View style={{ flex: 1 }}>
        <ImageBackground
          source={{
            uri:
              api.api_url +
              this.state.projecte["FonsPantalla2" + this.state.idioma].url,
          }}
          style={style.imageBackground}
        >
          <View
            style={{
              marginTop: this.state.pop_up_margin_top * 1.2,
              height: this.state.pop_up_height * 1,
            }}
          >
            <View
              style={[
                style.caixaOut,
                this.state.projecte.BorderLogin
                  ? { backgroundColor: this.state.projecte.colorPrincipal2 }
                  : null,
              ]}
            >
              <View
                style={[
                  style.caixa,
                  this.state.projecte.BorderLogin ? style.BorderLogin : null,
                  { borderColor: this.state.projecte.ColorBorderContenidor },
                ]}
              >
                <View style={{ alignContent: "center" }}>
                  <Image
                    style={{
                      height: this.state.pop_up_height * 0.63,
                      resizeMode: "contain",
                    }}
                    source={
                      this.state.projecte.ImatgeLogin
                        ? {
                            uri:
                              api.api_url + this.state.projecte.ImatgeLogin.url,
                          }
                        : null
                    }
                  />
                </View>

                <View style={{ alignContent: "center", paddingTop: 0 }}>
                  <TextInput
                    autoCorrect={false}
                    placeholder={this.state.placeholder}
                    placeholderTextColor={style.colorCorporatiu}
                    //underlineColorAndroid="transparent"
                    returnKeyType="send"
                    onChangeText={(text) => this.setState({ nom: text })}
                    style={style.textNom}
                    value={this.state.nom}
                  />
                  {/* <Text style={{ borderColor: '#891812',borderTopWidth:2,alignSelf: 'center',width: '70%',marginTop:5 }}> </Text> */}
                </View>

                <TouchableOpacity
                  onPress={() => {
                    this.send();
                  }}
                >
                  <Image
                    style={{
                      width: (Dimensions.get("screen").width * 30) / 100,
                      height: (Dimensions.get("screen").height * 10) / 100,
                      alignSelf: "center",
                      resizeMode: "contain",
                    }}
                    source={{
                      uri:
                        api.api_url +
                        this.state.projecte["botoLogin" + this.state.idioma][0]
                          ?.url,
                    }}
                  />
                  {/* <Text style={style.textLogin}>{this.state.projecte.botoLogin.url}</Text> */}
                </TouchableOpacity>
              </View>
            </View>
          </View>
          {/* {this.mostrarBarraDeLogin()} */}
          <View></View>
        </ImageBackground>
      </View>
    );
  }
}
