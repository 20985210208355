import React from "react";
import {
  View,
  Text,
  ImageBackground,
  Image,
  ScrollView,
  Dimensions,
  TouchableHighlight,
  TouchableWithoutFeedback,
} from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import style from "./style";
import Menu from "../menu";
import Modal from "modal-react-native-web";
import api from "../config/api_urls";

export default class Perfil extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.route.params;
    this.state["premis"] = [];
    this.state["imatge"] = [];
    this.state["postes"] = [];
    this.state["joc"] = "";
    this.state["gameList"] = [];
    this.state["visible"] = false;
    this.state["SobreVisible"] = false;
    this.state["imgClick"] = 0;
    this.state["jaRenderitzat"] = false;

    if (typeof this.state.nom === "undefined") {
      this.state["nom"] = "";
    }
    if (typeof this.state.cookies === "undefined") {
      this.state["cookies"] = "";
    }
  }

  componentDidMount() {
    this.getGameList();
    this.getNom();
    // this.isCardRended();
    AsyncStorage.setItem("anarCredits", true);
    setTimeout(() => {
      this.getGamePoints();
    }, 50);
  }

  // isCardRended = async () => {
  //   try {
  //     const value = await AsyncStorage.getItem('jaRenderitzat');
  //     if (value !== null) {
  //       this.setState({ jaRenderitzat : value });
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  getGamePoints = async () => {
    let punts = 0;
    let length = 0;
    let postes = [];
    let passedPois = [];
    for (let i = 0; i < this.state.gameList.length; i++) {
      const value = await AsyncStorage.getItem(
        "Game" + [this.state.gameList[i]]
      );
      if (value !== null) {
        //console.log(JSON.parse(value).idioma);
        if (JSON.parse(value).idioma == this.state.idioma) {
          punts += JSON.parse(value).score;
          postes += JSON.parse(value).gainedRewardsIds + ",";
          passedPois += JSON.parse(value).passedPoisIds + ",";
          let aux = passedPois.split(",");
          length = aux.length - 1;
        }
      }
    }
    this.setState({
      joc: {
        score: punts,
        gainedRewardsIds: postes,
        passedPoisIds: passedPois,
        pois: length,
      },
    });
  };

  getGameList = async () => {
    try {
      const value = await AsyncStorage.getItem("Gamelist");
      if (value !== null) {
        this.setState({ gameList: value.split(",") });
      }
    } catch (e) {
      console.log(e);
    }
  };
  getNom = async () => {
    try {
      const value = await AsyncStorage.getItem("nom");
      if (value !== null) {
        this.setState({ nom: value });
      }
    } catch (e) {
      console.log(e);
    }
  };

  imatges() {
    return this.state.projecte.premis.map((data, i) => {
      if (data.superPremi) {
        if (i + 1 == this.state.joc.pois) {
          //get last
          return this.animacioCarta(data, i);
        } else {
          return this.superPremi(data, i);
        }
      } else {
        return this.renderitzarPremi(data, i);
      }
    });
  }

  animacioCarta(data, i) {
    if (!this.state.jaRenderitzat) {
      setTimeout(() => {
        this.setSobreVisible(true);
        this.setState({ imgClick: i });
        this.setState({ jaRenderitzat: true });
      }, 100);
      return null;
    } else {
      return this.superPremi(data, i);
    }
  }

  superPremi(data, i) {
    return (
      <View style={{ width: "100%", alignContent: "center" }}>
        <View style={{ alignItems: "center" }}>
          <TouchableHighlight
            style={[style.button]}
            onPress={() => {
              this.setModalVisible(true);
              this.setState({ imgClick: i });
            }}
          >
            {this.isInPassedPois(data["poi_id" + this.state.idioma]) ? (
              <Image
                style={[
                  style.superPremiImg,
                  this.isInPassedPois(data["poi_id" + this.state.idioma])
                    ? null
                    : style.difuminat,
                ]}
                source={{ uri: api.api_url + data.Premi.url }}
              />
            ) : (
              <Image
                style={style.noAconseguit}
                source={require("../../img/premiUndefined.png")}
              />
            )}
          </TouchableHighlight>
          <Text
            style={{
              alignSelf: "center",
              color: "white",
              fontWeight: 500,
              fontSize: 10,
              paddingVertical: 5,
              pdingRight: 5,
            }}
          ></Text>
        </View>
      </View>
    );
  }

  //Renderitzar Premi tant vertical com horitzontal
  renderitzarPremi(data, i) {
    //if(this.state.projecte.PremiVertical){
    return (
      <View
        style={[
          this.state.projecte.MarcPremis
            ? {
                backgroundColor: this.state.projecte.ColorMarcPremis,
                paddingHorizontal: 1,
                paddingTop: 5,
                paddingBottom: 15,
                borderRadius: 10,
                marginHorizontal: 5,
              }
            : { width: "33%", alignContent: "center" },
        ]}
      >
        <View style={{ alignItems: "center" }}>
          <TouchableHighlight
            style={[style.button]}
            onPress={() => {
              this.setModalVisible(true);
              this.setState({ imgClick: i });
            }}
          >
            {this.isInPassedPois(data["poi_id" + this.state.idioma]) ? (
              this.isInRewardsPoints(data["poi_id" + this.state.idioma]) ? (
                <Image
                  style={style.aconseguit}
                  source={{ uri: api.api_url + data.Premi.url }}
                />
              ) : (
                <Image
                  style={[style.noAconseguit, { opacity: 0.3 }]}
                  source={{ uri: api.api_url + data.Premi.url }}
                />
              )
            ) : (
              <View style={{ alignItems: "center" }}>
                <Image
                  style={style.noAconseguit}
                  source={require("../../img/premiUndefined.png")}
                />
              </View>
            )}
          </TouchableHighlight>
          <Text
            style={{
              alignSelf: "center",
              color: "white",
              fontWeight: 500,
              fontSize: 10,
              paddingVertical: 5,
              pdingRight: 5,
            }}
          ></Text>
        </View>
      </View>
    );
  }

  isInRewardsPoints(id) {
    if (
      this.state.joc.gainedRewardsIds != null &&
      this.state.joc.gainedRewardsIds != undefined
    ) {
      if (this.state.joc.gainedRewardsIds.includes(id)) {
        return true;
      } else return false;
    }
    return false;
  }

  isInPassedPois(id) {
    if (this.state.joc.passedPoisIds != null) {
      return this.state.joc.passedPoisIds.includes(id);
    } else {
      return false;
    }
  }

  /*********** 
  MODAL
 ***********/
  setModalVisible = (visible) => {
    this.setState({ visible });
  };

  setSobreVisible = (SobreVisible) => {
    this.setState({ SobreVisible });
  };

  render() {
    return (
      <TouchableWithoutFeedback
        onPress={() => this.setState({ visible: false })}
      >
        <View style={[{ flex: 1 }]}>
          <View style={{ flex: 15 }}>
            <ImageBackground
              source={
                this.state.projecte["FonsPantalla7" + this.state.idioma]
                  ? {
                      uri:
                        api.api_url +
                        this.state.projecte["FonsPantalla7" + this.state.idioma]
                          .url,
                    }
                  : null
              }
              style={style.imageBackground}
            >
              <View
                style={
                  this.state.projecte.MidaMaximaPagines
                    ? null
                    : {
                        marginTop: this.state.pop_up_margin_top,
                        maxHeight: this.state.pop_up_height,
                        paddingBottom: Dimensions.get("screen").width * 0.15,
                      }
                }
              >
                <View
                  style={[
                    style.caixaOut,
                    { backgroundColor: this.state.projecte.colorPrincipal2 },
                  ]}
                >
                  <View
                    style={[
                      style.caixa,
                      this.state.projecte.BorderContenidor
                        ? style.caixaBorderContenidor
                        : null,
                      {
                        borderColor: this.state.projecte.ColorBorderContenidor,
                      },
                    ]}
                  >
                    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                      <View
                        style={{
                          alignItems: "center",
                          paddingTop: 4,
                          borderBottomWidth: 2,
                          borderColor: "#891812",
                          marginHorizontal: 30,
                        }}
                      >
                        {this.state.projecte.ImatgePerfil != null ? (
                          <Image
                            source={{
                              uri:
                                api.api_url +
                                this.state.projecte.ImatgePerfil.url,
                            }}
                            style={{
                              height: this.state.pop_up_height * 0.17,
                              width: this.state.pop_up_height * 0.17,
                              marginLeft: 7.5,
                              marginRight: 7.5,
                              flexWrap: "wrap",
                              alignItems: "stretch",
                            }}
                          />
                        ) : null}
                        <Text
                          style={[
                            style.titol,
                            { color: this.state.projecte.ColorCorporatiuText1 },
                          ]}
                        >
                          {
                            this.state.projecte[
                              "TitolPerfil" + this.state.idioma
                            ]
                          }
                        </Text>
                      </View>
                      <View
                        style={{
                          borderColor: "#891812",
                          marginHorizontal: "5%",
                          borderBottomWidth: 2,
                          marginHorizontal: 30,
                        }}
                      >
                        <Text
                          style={[
                            style.nom,
                            { color: this.state.projecte.ColorCorporatiuText1 },
                          ]}
                        >
                          {this.state.nom}
                        </Text>
                      </View>
                      <View
                        style={{
                          borderColor: "#891812",
                          marginHorizontal: "5%",
                        }}
                      >
                        <View style={{ alignSelf: "center", paddingTop: 5 }}>
                          <Text
                            style={[
                              style.puntsText,
                              {
                                color: this.state.projecte.ColorCorporatiuText2,
                              },
                            ]}
                          >
                            {
                              this.state.projecte[
                                "PuntsPerfil" + this.state.idioma
                              ]
                            }
                          </Text>
                          <Text
                            style={[
                              style.text2,
                              {
                                color: this.state.projecte.ColorCorporatiuText1,
                              },
                            ]}
                          >
                            {this.state.joc.score}
                          </Text>
                        </View>

                        <View style={{ borderColor: "white" }}>
                          <View style={{ paddingTop: 4 }}>
                            <Text
                              style={[
                                style.puntsText,
                                {
                                  color:
                                    this.state.projecte.ColorCorporatiuText2,
                                },
                              ]}
                            >
                              {
                                this.state.projecte[
                                  "RecompensesPerfil" + this.state.idioma
                                ]
                              }
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "center",
                                paddingTop: 20,
                                alignItems: "center",
                              }}
                            >
                              {this.imatges()}
                            </View>
                          </View>
                        </View>
                      </View>
                    </ScrollView>
                  </View>
                </View>
              </View>
              {this.state.visible ? (
                <View
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: "rgba(216, 216, 216, 0.6)",
                  }}
                ></View>
              ) : null}
              {this.state.SobreVisible ? (
                <View
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: "rgba(216, 216, 216, 0.6)",
                  }}
                ></View>
              ) : null}
            </ImageBackground>
          </View>
          <View
            style={{ flex: 2, bottom: 0, left: 0, right: 0, position: "fixed" }}
          >
            <Menu projecte={this.state}></Menu>
          </View>

          {/* Modal premi  */}
          <Modal
            animationType="slide"
            transparent={true}
            ariaHideApp={false}
            style={{ backgroundColor: "blue" }}
            onBackdropPress={() => {
              this.setState({ visible: false });
            }}
            visible={this.state.visible}
          >
            <View style={{ marginTop: 22 }}>
              <TouchableWithoutFeedback
                onPress={() => {
                  this.setState({ visible: false });
                }}
              >
                <View
                  style={
                    this.state.projecte.MidaMaximaPagines
                      ? null
                      : {
                          marginTop: this.state.pop_up_margin_top,
                          maxHeight: this.state.pop_up_height,
                          paddingBottom: Dimensions.get("screen").width * 0.1,
                          marginHorizontal: "10%",
                        }
                  }
                >
                  <View style={style.caixaOut}>
                    <View
                      style={[
                        style.caixa,
                        this.state.projecte.BorderContenidor
                          ? style.caixaBorderContenidor
                          : null,
                        {
                          borderColor:
                            this.state.projecte.ColorBorderContenidor,
                        },
                      ]}
                    >
                      <View style={[style.caixa, { marginTop: 30 }]}>
                        <Image
                          style={style.imatgeClick}
                          source={
                            this.state.projecte.premis.length != 0
                              ? {
                                  uri:
                                    api.api_url +
                                    this.state.projecte.premis[
                                      this.state.imgClick
                                    ]?.Premi.url,
                                }
                              : null
                          }
                        />
                        <Text style={style.textImatge}>
                          {this.state.projecte.premis.length != 0
                            ? this.state.projecte.premis[this.state.imgClick][
                                "Nom" + this.state.idioma
                              ]
                            : null}{" "}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </Modal>

          {/* Modal Animacio de carta */}
          <Modal
            animationType="fade"
            transparent={true}
            ariaHideApp={false}
            style={{ backgroundColor: "blue" }}
            onBackdropPress={() => {
              this.setState({ visible: true });
              this.setState({ SobreVisible: false });
            }}
            visible={this.state.SobreVisible}
          >
            <View style={{ marginTop: 22 }}>
              <TouchableWithoutFeedback
                onPress={() => {
                  this.setState({ visible: true });
                  this.setState({ SobreVisible: false });
                }}
              >
                <View
                  style={
                    this.state.projecte.MidaMaximaPagines
                      ? null
                      : {
                          marginTop: this.state.pop_up_margin_top,
                          maxHeight: this.state.pop_up_height,
                          paddingBottom: Dimensions.get("screen").width * 0.1,
                          marginHorizontal: "10%",
                        }
                  }
                >
                  <View style={style.caixaOut}>
                    <Image
                      style={style.SobreClick}
                      source={{
                        uri: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Cover_Bahamas_1929_front.jpg",
                      }}
                    />
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </Modal>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
