import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "column"
    },

    imageBackground: {
      flex: 1,
      resizeMode: "contain",
      justifyContent: "flex-end",
      
    },

    image: {
      resizeMode: "center",
      alignSelf: "center",
      marginBottom: 200,
      marginTop: 50
    },

    text: {
      color: "white",
      fontSize: 42,
      fontWeight: "bold",
      textAlign: "center",
      backgroundColor: "#000000a0",
    },

    TouchableOpacity: {
      position: "absolute", 
      top: 0, 
      right: 1, 
      left: 1, 
      bottom: 0
    }
  });
