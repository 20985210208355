import React, { useState, useRef } from "react";
import { PermissionsTunnel } from "iframe-permissions-tunnel";
import { Dimensions } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";

export const PlayvisitEmbed = ({ link , idioma, props , state }) => {
  const iframeRef = useRef(null);
  const [isShowingPrompt, setIsRequestingPermissions] = useState(false);

const onIframeLoad = () => {

  async function setupTunnel() {
    PermissionsTunnel.forwardTo(iframeRef.current.contentWindow);

    PermissionsTunnel.onPermissionPromptShown(() =>
      setIsRequestingPermissions(true)
    );
    PermissionsTunnel.onPermissionPromptHidden(() =>
      setIsRequestingPermissions(false)
    );
     PermissionsTunnel.registerCallback("playvisit/session-created",({gameId, gameToken}) => {
        console.log("entra?"+gameToken);
        localStorage.setItem("gameToken_"+gameId, gameToken);
      });
      

      PermissionsTunnel.registerCallback("playvisit/poi-update", msg => {
        console.log("game_"+msg.gameId);
        //console.log("Prova=>" + idioma);
        msg.idioma = idioma;
        console.log(msg);
        let aux =  localStorage.getItem('Gamelist');
        var Gamelist = aux  
          if(Gamelist !== null){
            Gamelist = Gamelist.toString();
            if(!Gamelist.includes(""+msg.gameId)){
              
              localStorage.setItem('Gamelist',Gamelist +","+ msg.gameId);
            }
          }else{
            localStorage.setItem('Gamelist',msg.gameId);
          }
          getPoint(msg)
          
      });        
  }
  setupTunnel();
};

async function getPoint(msg){
  
  let data = await localStorage.getItem('Game'+msg.gameId); 
  if(data != null){
    let gameId = JSON.parse(data);
    console.log(gameId);
    if(gameId !== null && gameId != undefined){

      if( gameId.passedPoisIds.length <= msg.passedPoisIds.length){
        localStorage.setItem('Game'+msg.gameId,JSON.stringify(msg));
        //find Poi
        let isTrucada;
        switch(state.idioma)
        {
          case '_FR':
          isTrucada = state.projecte.premis.find((element)=> element.poi_id_FR == msg.passedPoisIds[msg.passedPoisIds.length -1] );
          break;
          case '_EN':
          isTrucada =state.projecte.premis.find((element)=> element.poi_id_EN == msg.passedPoisIds[msg.passedPoisIds.length -1] );
          break;
          case '_ES':
          isTrucada =state.projecte.premis.find((element)=> element.poi_id_ES == msg.passedPoisIds[msg.passedPoisIds.length -1] );
          break;
          default :
          isTrucada =state.projecte.premis.find((element)=> element.poi_id == msg.passedPoisIds[msg.passedPoisIds.length -1] );

        }
      

        if(isTrucada.Trucada){      
          if(isTrucada.isLastPoi){
            console.log("entra poi");
            state.premiTrucada = isTrucada;
            AsyncStorage.setItem('lastPoiTrucada', JSON.stringify(isTrucada));
          }else{
            console.log("entra poi sense ser l'ultim");
            state.premiTrucada = isTrucada;
            
            if(state.projecte.anarDirecteTrucada){
              props.navigation.navigate('Video', state);
            }else{
             props.navigation.navigate('Telefon', state);
            }
          }
        }
      }else{
        console.log("Error game");
      }
    } 
  }else{
    localStorage.setItem('Game'+msg.gameId,JSON.stringify(msg));
  }

}

  return (
    <iframe
      ref={iframeRef}
      onLoad={onIframeLoad}
      height={(Dimensions.get('window').height/100)*89}
      width="99.9%"
      src={link}
      allow="fullscreen;geolocation;camera;gyroscope;accelerometer;magnetometer"
    />
  );
};

// if(state.projecte["Trucada"+idioma] == msg.passedPoisIds[msg.passedPoisIds.length -1] ){ 




  // if( gameId.passedPoisIds.length <= msg.passedPoisIds.length){
  //   localStorage.setItem('Game'+msg.gameId,JSON.stringify(msg));
  
  //   if(state.projecte.premis[msg.passedPoisIds.length -1].trucada){   
  //     state.premiTrucada = state.projecte.premis[msg.passedPoisIds.length-1] 
      
  //     if(state.projecte.anarDirecteTrucada){
  //       props.navigation.navigate('Video', state);
  //     }else{
  //       setTimeout(()=>{props.navigation.navigate('Telefon', state);},5000)
  //     }
  // }

  //      if( gameId.passedPoisIds[gameId.passedPoisIds.length ] == msg.passedPoisIds[msg.passedPoisIds.length -1]){
