import React from "react";
import {
  Dimensions,
  Image,
  ImageBackground,
  ScrollView,
  Text,
  View,
} from "react-native";
import { StatusBar } from "expo-status-bar";
import style from "./style";
import Menu from "../menu";
import api from "../config/api_urls";
import ReactMarkdown from "react-markdown";
import AsyncStorage from "@react-native-community/async-storage";
import { PlayvisitEmbed } from "../joc2/PlayvisitEmbed";

export default class Pagina6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.route.params;
    // console.log(this.state);
  }

  componentDidMount() {
    // console.log("entra Pag 6");
    //Serveix per redirecionar al menu o als creditsInici
    AsyncStorage.setItem("anarCredits", true);
  }

  plataforma() {
    return this.state.projecte.pagines.map((dades) => {
      if (
        dades.Nom.toLowerCase() ===
        this.state.projecte.BotoMenu5Url.toLowerCase()
      ) {
        if (dades.Tipus == "Tipus1") {
          return (
            <View
              style={{
                marginTop: this.state.pop_up_margin_top,
                maxHeight: this.state.pop_up_height,
                paddingBottom: Dimensions.get("screen").width * 0.15,
              }}
            >
              <View
                style={[
                  style.caixaOut,
                  { backgroundColor: this.state.projecte.colorPrincipal2 },
                ]}
              >
                <View
                  style={[
                    style.caixa,
                    this.state.projecte.BorderContenidor
                      ? style.caixaBorderContenidor
                      : null,
                    { borderColor: this.state.projecte.ColorBorderContenidor },
                  ]}
                >
                  <ScrollView>
                    <View style={{ flex: 1, justifyContent: "center" }}>
                      <Image
                        style={{
                          height: this.state.pop_up_height * 0.17,
                          resizeMode: "contain",
                          paddingTop: 5,
                        }}
                        source={{ uri: api.api_url + dades.Imatge.url }}
                      />
                      <Text
                        style={[
                          style.titol,
                          { color: this.state.projecte.ColorCorporatiuText1 },
                        ]}
                      >
                        {dades["Nom" + this.state.idioma]}
                      </Text>
                      <View
                        style={{
                          paddingTop: 5,
                          marginHorizontal: "5%",
                          color: this.state.projecte.ColorCorporatiuText2,
                          fontSize: 13,
                          textAlign: "justify",
                          fontFamily: "OpenSans",
                        }}
                      >
                        <ReactMarkdown style={style.text}>
                          {dades["Tipus1Text" + this.state.idioma]}
                        </ReactMarkdown>
                      </View>
                    </View>
                  </ScrollView>
                </View>
              </View>
            </View>
          );
        } else {
          //Mostrar ifame Joc2
          return (
            <View style={{ flex: 1, backgroundColor: "#fff" }}>
              <StatusBar style="auto" />
              <PlayvisitEmbed link={dades["LinkTipus2" + this.state.idioma]} />
            </View>
          );
        }
      }
    });
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 15 }}>
          <ImageBackground
            source={
              this.state.projecte["FonsPantalla4" + this.state.idioma]
                ? {
                    uri:
                      api.api_url +
                      this.state.projecte["FonsPantalla4" + this.state.idioma]
                        .url,
                  }
                : null
            }
            style={style.imageBackground}
          >
            {this.plataforma()}
          </ImageBackground>
        </View>
        <View
          style={{ flex: 2, bottom: 0, left: 0, right: 0, position: "fixed" }}
        >
          <Menu projecte={this.state}></Menu>
        </View>
      </View>
    );
  }
}
