import React from "react";
import { ImageBackground, TouchableOpacity, View } from "react-native";
import style from "./style";
import api from "../config/api_urls";
import AsyncStorage from "@react-native-community/async-storage";

//ordre 4
class MenuPosicionament extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.route.params;
    this.state["navigation"] = this.props.navigation;

    this.pages =
      typeof this.state.projecte !== "undefined" &&
      typeof this.state.projecte.pagines !== "undefined"
        ? this.state.projecte.pagines
        : [];
    this.state["selectedPage"] = {};
  }

  componentDidMount() {
    this.getIdioma();
  }

  getIdioma = async () => {
    try {
      const value = await AsyncStorage.getItem("idioma");
      if (value !== null) {
        this.setState({ idioma: value });
      }
    } catch (e) {
      console.log(e);
    }
  };

  loadPagina(tipus, props) {
    this.state["selectedPage"] = props;
    if (tipus == "Tipus1") {
      AsyncStorage.setItem("anarCredits", true);
      this.props.navigation.navigate("Joc1", this.state);
    } else if (tipus == "Tipus2") {
      AsyncStorage.setItem("anarCredits", false);
      this.props.navigation.navigate("Joc2", this.state);
    }
  }
  changeScreenOrientation = async () => {
    await ScreenOrientation.lockAsync(
      ScreenOrientation.OrientationLock.PORTRAIT
    );
  };

  loadPagina(tipus, props) {
    this.state["selectedPage"] = props;
    if (tipus == "Tipus1") {
      AsyncStorage.setItem("anarCredits", true);
      this.props.navigation.navigate("Joc1", this.state);
    } else if (tipus == "Tipus2") {
      AsyncStorage.setItem("anarCredits", false);
      this.props.navigation.navigate("Joc2", this.state);
    } else if (tipus == "360") {
      this.props.navigation.navigate("Joc3", this.state);
    } else if (tipus == "Tipus3") {
      AsyncStorage.setItem("anarCredits", false);
      this.props.navigation.navigate("Pagina6", this.state);
    } else if (tipus == "Tipus4") {
      AsyncStorage.setItem("anarCredits", false);
      this.props.navigation.navigate("Itineraris", this.state);
    }
  }

  render() {
    return (
      <View style={{ flex: 15 }}>
        <ImageBackground
          source={{
            uri:
              api.api_url +
              this.state.projecte["FonsPantalla3" + this.state.idioma].url,
          }}
          style={style.imageBackgroundHoritzontal}
        >
          <View style={{ flex: 1, width: "100%", paddingTop: "30%" }}>
            <View style={{ flex: 3 }}>
              <TouchableOpacity
                style={{ flex: 1 }}
                onPress={() => {
                  this.loadPagina("Tipus1", this.state.projecte.pagines[0]);
                }}
              ></TouchableOpacity>
            </View>
            <View style={{ flex: 3 }}>
              <TouchableOpacity
                style={{ flex: 1 }}
                onPress={() => {
                  this.loadPagina("360", this.state);
                }}
              ></TouchableOpacity>
            </View>
            <View style={{ flex: 3 }}>
              <TouchableOpacity
                style={{ flex: 1 }}
                onPress={() => {
                  this.state.navigation.navigate("Credits", this.state);
                }}
              ></TouchableOpacity>
            </View>
            <View style={{ flex: 3 }}>
              <TouchableOpacity
                style={{ flex: 1 }}
                onPress={() => {
                  this.state.navigation.navigate("Perfil", this.state);
                }}
              ></TouchableOpacity>
            </View>
            <View style={{ flex: 5, width: "50%", alignSelf: "center" }}>
              <TouchableOpacity
                style={{ flex: 1 }}
                onPress={() => {
                  this.loadPagina("Tipus2", this.state.projecte.pagines[1]);
                }}
              ></TouchableOpacity>
            </View>
          </View>
        </ImageBackground>

        {this.state.finalitzacioItinerari &&
        this.state.finalitzacioItinerari !== ""
          ? this._renderPopUp()
          : ""}
      </View>
    );
  }
}
export default MenuPosicionament;

// const items = []
// if(this.pages.length > 0) {
//   for (const [index, value] of this.pages.entries()) {
//     if(value.Tipus != "Tipus3"){
//     items.push(
//       <View style={{flex:1}}>
//         <TouchableOpacity key={index}  onPress={()=>{ this.loadPagina(value.Tipus, value); }}>
//           <View style={{width: ((Dimensions.get('screen').width /100 * 50) + (Dimensions.get('screen').width /100 * 25)), height:( Dimensions.get('screen').height/100 * 20),position:'absolute',}}>
//               <Image source={{ uri: api.api_url + value.Imatge.url}} style={{height: this.state.pop_up_height * 0.12, resizeMode: 'contain', marginTop: 1}}></Image>
//           </View>
//         </TouchableOpacity>
//       </View>
//     )
//   }
// }
// }
