//var api_url = 'http://iternaturastrapi.ferranmaso.com';
//var api_url = 'https://iternaturastrapi.cenobify.com'
//var token = 'hejoi32nyrt8rn32ued8fnsy78nr3ui2o1lkdkkajfidsa';

//  var api_url = "https://iternaturastrapi.ferranmaso.com";
//  var api_url = "https://catcar-wa.iec.cat/"
 var api_url = "https://strapiold.iternatura.es"
 var token = 'hejoi32nyrt8rn32ued8fnsy78nr3ui2o1lkdkkajfidsa';
 var tokenClio= 'NRA1tGCl8ZHetrLONKory8L7e4kB7i0I';
 var tokenTon = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9';
export default {
  api_url: api_url,
  projecte: api_url +'/projectes?token=' + token,
  premis: api_url +'/premis',
  clio : api_url + '/projectes?token='+tokenClio,
  ton : api_url + '/projectes?token='+tokenTon
}; 